
.carousel-indicators{
    text-align: right !important;
    float: right !important;
    right: 0px !important;
    bottom: 10px !important;
    left: inherit !important;
}

.carousel-indicators button {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    opacity: 50% !important;
    background-color: #FFFFFF !important;
    height: 2px !important;
    width: 70px !important;
    border-radius: 10px !important;
}

.carousel-indicators .active {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: #FFFFFF !important;
    opacity: 100% !important;
    height: 2px !important;
    width: 70px !important;
    border-radius: 10px !important;
}


/* .carousel-indicators {
    border-top: unset ;
    width: 10px !important; 
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: green!important;
    height: 10px;
    width: 20px;
} */

.carousel-controls {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    border: none;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:600px){
        top: 90% !important;
        right: 10% !important;
    } 
}

.carousel-controls button {
    margin: 0 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.carousel-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.carousel-controls.left {
    left: -40px;
    @media (max-width:600px){
        left: 10px;
        right: -40px !important;
    } 
}

.carousel-controls.right {
    right: -40px;
   
}