@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  margin: 0;
  font-family: "Poppins";
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

body.no-scroll{
  overflow: hidden;
}


code {
  font-family: "Poppins";

}
